// eslint-disable import/namespace
import React, { FC, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import IconSvg from 'ui/Svg/IconSvg';
import { getBankAccountsWithSelectFilters } from 'redux/reducers/transactionsFilters/selectors';
import { addFilter, deleteFilter, resetFilters } from 'redux/reducers/transactionsFilters/reducer';
import { EFiltersType, ETransactionsFilters } from 'redux/reducers/transactionsFilters/types';
import { convertFilterObjectToParamsObject } from 'services/utils/convertFilterObjectToParamsObject';
import { IApiGetTransactionParams } from 'services/api/transactions/types';
import TableBodyNoData from 'ui/TableBodyNoData/TableBodyNoData';
import PerPageSelect from 'ui/PerPageSelect/PerPageSelect';
import SearchWithSelect, { ISearchSelectArr } from 'ui/SearchWithSelect/SearchWithSelect';
import ActiveFilters from 'ui/ActiveFilters/ActiveFilters';
import { getDepositAccounts, getUserDepositAccounts } from 'redux/reducers/walletBalance/selectors';
import { activateResetActiveBankAccounts } from 'redux/reducers/bankAccounts/reducer';
import {
	getDepositAccountRequest,
	getUserDepositAccountRequest,
} from 'redux/reducers/walletBalance/reducer';
import { currencyDataRequest } from 'redux/reducers/currency/reducer';
import TableHeader from './TableHeader';
import TableBody from './TableBody';

const FiltersSearchArray = [
	{ name: 'Beneficiary Name', value: ETransactionsFilters.BENEFICIARY_NAME },
	{ name: 'Beneficiary Address', value: ETransactionsFilters.BENEFICIARY_ADDRESS },
	{ name: 'Bank Name', value: ETransactionsFilters.BANK_NAME },
	{ name: 'Bank Address', value: ETransactionsFilters.BANK_ADDRESS },
	{ name: 'SWIFT/BIC', value: ETransactionsFilters.SWIFT_BIC },
	{ name: 'Account Number', value: ETransactionsFilters.ACCOUNT_NUMBER },
];

export interface IBankAccountsWithSelectProps {
	userId?: number;
	resetStates?: boolean;
	withoutAction?: boolean;
	editMode?: boolean;
}

const AssociatedAccounts: FC<IBankAccountsWithSelectProps> = ({
	userId,
	resetStates,
	withoutAction,
	editMode,
}) => {
	const dispatch = useDispatch();
	const bankAccounts = useSelector(userId ? getUserDepositAccounts : getDepositAccounts);
	const activeFilters = useSelector(getBankAccountsWithSelectFilters);

	const [currentPage, setCurrentPage] = useState(bankAccounts?.current_page || 1);
	const [resetSearchValue, setResetSearchValue] = useState(false);
	const [perPage, setPerPage] = useState<number | undefined>(10);
	const handleSetPerPage = (value: number | undefined) => {
		setPerPage(value || bankAccounts?.total);
		setCurrentPage(1);
	};

	const handlePageClick = ({ selected }: { selected: number }) => {
		setCurrentPage(selected + 1);
	};

	const pageCount = bankAccounts?.last_page ? bankAccounts.last_page : 1;

	useLayoutEffect(() => {
		if (currentPage > pageCount) {
			setCurrentPage(1);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bankAccounts?.last_page]);

	useLayoutEffect(() => {
		dispatch(currencyDataRequest());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useLayoutEffect(() => {
		setPerPage(10);
		setCurrentPage(1);
		setResetSearchValue(false);
		dispatch(resetFilters(EFiltersType.BANK_ACCOUNTS_WITH_SELECT));
		dispatch(activateResetActiveBankAccounts());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userId, resetStates]);

	useLayoutEffect(() => {
		const filterParams = convertFilterObjectToParamsObject(activeFilters);

		const params: IApiGetTransactionParams = {
			per_page: perPage,
			current_page: currentPage,
			...filterParams,
		};

		!userId && dispatch(getDepositAccountRequest(params));
		!!userId && dispatch(getUserDepositAccountRequest({ userId, params }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeFilters, perPage, currentPage, userId]);

	const handleResetSearchValue = () => {
		setResetSearchValue(!resetSearchValue);
	};

	const handleDeleteFilter = (filterType: ETransactionsFilters) => {
		handleResetSearchValue();
		dispatch(
			deleteFilter({ type: filterType, filterType: EFiltersType.BANK_ACCOUNTS_WITH_SELECT }),
		);
	};

	const handleResetFilters = () => {
		handleResetSearchValue();
		dispatch(resetFilters(EFiltersType.BANK_ACCOUNTS_WITH_SELECT));
	};

	const handleSearch = (searchField: ISearchSelectArr, currentSearchValue: string) => {
		if (currentSearchValue === '') {
			dispatch(
				deleteFilter({
					type: searchField.value as ETransactionsFilters,
					filterType: EFiltersType.BANK_ACCOUNTS_WITH_SELECT,
				}),
			);
		} else {
			dispatch(
				addFilter({
					type: searchField.value as ETransactionsFilters,
					value: currentSearchValue,
					name: `${searchField.name}: ${currentSearchValue}`,
					filterType: EFiltersType.BANK_ACCOUNTS_WITH_SELECT,
				}),
			);
		}
	};
	const accounts = [
		{
			accountName: 'Cody Fisher',
			main: 'Main',
			roles: ['Admin'],
			action: true,
		},
		{
			accountName: 'Praise Network LTD',
			main: '',
			roles: ['Viewer', 'Initiator', 'Approver'],
			action: false,
		},
		{
			accountName: 'Northbank Corp.',
			main: '',
			roles: ['Trader'],
			action: false,
		},
	];
	return (
		<div className="associated-accounts">
			<div className="table-block">
				<div className="table-wrapper ">
					<div className="table table--associated-accounts">
						<div className="table-header">
							<TableHeader />
						</div>
						<div className="table-body">
							{accounts ? (
								accounts?.map((item: any) => <TableBody key={item.id} item={item} />)
							) : (
								<TableBodyNoData />
							)}
						</div>
					</div>
				</div>
			</div>

			{!!bankAccounts?.data.length && (
				<div className="table-footer table-footer--bank-accounts-with-select">
					<div className="pagination-block pagination-block--full">
						<PerPageSelect onChange={handleSetPerPage} />
						{pageCount > 1 && (
							<ReactPaginate
								breakLabel="..."
								pageCount={1}
								// onPageChange={handlePageClick}
								forcePage={currentPage - 1}
								className="pagination"
								activeClassName="active"
								previousClassName="arrow arrow--prev"
								nextClassName="arrow arrow--next"
								previousLabel={<IconSvg name="keyboard-arrow-left" w="10" h="16" />}
								nextLabel={<IconSvg name="keyboard-arrow-right" w="10" h="16" />}
							/>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

export default AssociatedAccounts;
